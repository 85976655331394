<template>
  <Datatable v-bind:entries="parts" :columns="columns" :currEntries="50" :title="'Part'"/>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
      ...mapState('parts', ['parts']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'part_name', text: 'Part Name', order: 'asc'},
            {name: 'part_code', text: 'Part Code'},
            {name: 'uom', text: 'UOM'},
            {name: 'brand', text: 'Brand'},
            {name: 'sku_number', text: 'SKU Number'},
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('edit_part', this.user) ? {event: 'edit_part', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                  getCapability('delete_part', this.user) ? {event: 'parts/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
        }
    },
    components:{
      Datatable,
    },
    methods: {
      handleSubmit(){
        this.get_all()
      },
      ...mapActions('parts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Parts', getCapability('add_part', this.user) ? '/parts/add' : '')
      this.get_all()
    },
  }
</script>
