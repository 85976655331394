<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="custom_event_alert.account" class="form-group col-sm-12">
          <label for="account_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="custom_event_alert.account.account_name">
      </div>
      <div v-if="custom_event_alert.vehicle" class="form-group col-sm-12">
          <label for="vehicle">Vehicle:</label>
          <input class="form-control" disabled name="vehicle" type="text" v-model="custom_event_alert.vehicle.vehicle_no">
      </div>
      <div v-if="custom_event_alert.driver" class="form-group col-sm-12">
          <label for="vehicle">Driver:</label>
          <input class="form-control" disabled name="driver" type="text" v-model="custom_event_alert.driver.name">
      </div>
      <div class="form-group col-sm-12 disabled">
        <label for="status">Type:</label>
        <input class="form-control" disabled name="type" type="text" v-model="custom_event_alert.type">
      </div>
      <div class="form-group col-sm-12">
        <label for="message">Alert Message:</label>
        <input class="form-control" disabled name="message" type="text" v-model="custom_event_alert.message">
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="lat">Latitude:</label>
        <input class="form-control" disabled name="lat" type="number" v-model.number="custom_event_alert.lat" step="any">
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="lng">Longitude:</label>
        <input class="form-control" disabled name="lng" type="number" v-model.number="custom_event_alert.lng" step="any">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Case Type:</label>
        <select class="form-control" required name="status" v-model="custom_event_alert.case_type">
          <option value="" style="color:black;">Please Select One</option>
          <option value="Driver Behaviour" style="color:black;">Driver Behaviour</option>
          <option value="Emergency" style="color:black;">Emergency</option>
          <option value="False Alarm" style="color:black;">False Alarm</option>
          <option value="Hardware Issue" style="color:black;">Hardware Issue</option>
          <option value="Hijack" style="color:black;">Hijack</option>
          <option value="Vehicle Issue" style="color:black;">Vehicle Issue</option>
          <option value="Route Block" style="color:black;">Route Block</option>
          <option value="Wrong Setting" style="color:black;">Wrong Setting</option>
          <option value="Others" style="color:black;">Others</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <label for="description">Description:</label>
        <textarea class="form-control" name="description" v-model="custom_event_alert.description"></textarea>
      </div>
      <div class="form-group col-sm-12">
        <label for="remarks">Remarks:</label>
        <textarea class="form-control" name="remarks" v-model="custom_event_alert.remarks"></textarea>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" required name="status" v-model.number="custom_event_alert.status">
          <option value="" style="color:black;">Please Select One</option>
          <option value="2" style="color:black;">In Progress</option>
          <option value="3" style="color:black;">Resolved</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/monitor_custom_event_alerts">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('custom_event_alerts', ['custom_event_alert', 'onRequest']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.custom_event_alert)
      },
      ...mapActions('custom_event_alerts', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit Custom Event Alert')
      this.get(this.$route.params.id)
    },
  }
</script>
