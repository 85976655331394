<template>
    <div class="row">
      <div v-if="visible" draggable="true" class="card" :style="'z-index:2000;cursor: move;position:fixed;top:'+this.top+';left:'+this.left"  @dragend="ondragend($event)" @dragstart="ondragstart($event)">
        <div class="card-header">
          <h5 class="card-title">
            Detail
          </h5>
          <button type="button" class="close float-right" @click="hideAlert()">×</button>
        </div>
        <div class="card-body">
          <table style="max-width:25rem">
            <tr><td>Vehicle No</td><td>:</td><td class="text-right">{{this.v.vehicle_no}}</td></tr>
            <tr><td>Vehicle Code</td><td>:</td><td class="text-right">{{this.v.vehicle_code}}</td></tr>
            <tr><td>Last Update</td><td>:</td><td class="text-right">{{getTime(this.v.gps_position.time)}}</td></tr>
            <tr><td>Status</td><td>:</td><td class="text-right">{{this.v.gps_position.online}}</td></tr>
            <tr><td>Speed</td><td>:</td><td class="text-right">{{this.v.gps_position.speed}} km/h</td></tr>
            <tr style="vertical-align:top;"><td>Address</td><td>:</td><td class="text-right">{{this.v.gps_position.address}}</td></tr>
            <tr><td>Last Driving</td><td>:</td><td class="text-right">{{getTimeUnix(this.v.gps_position.moved_timestamp)}}</td></tr>
            <tr><td>Location</td><td>:</td><td class="text-right"><a target="_blank" :href="'https://maps.google.com/?q='+this.v.gps_position.lat+','+this.v.gps_position.lng">Google</a></td></tr>
            <tr v-for="sensor in this.v.gps_position.sensors" :key="sensor">
              <td>{{sensor.name}}</td><td>:</td><td class="text-right"><span v-html="sensor.value"></span></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Multiselect mode="multiple" required v-model="account_id" :options="accs" valueProp="valueProp" label="label" :searchable="true">
                <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <span v-for="(item2, index2) in values" :key="index2">{{item2.label}},&nbsp;&nbsp;</span>
                  </div>
                </template>
              </Multiselect>
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div v-if="monitor_vehicle" class="col-sm-12">
        <div v-for="mon in monitor_vehicle" :key="mon" class="card card-default">
          <div class="card-header">
            <h3 class="text-center">
              {{mon.account.account_name}}
            </h3>
          </div>
          <div class="card-body">
            <div v-if="mon.maintenance && mon.maintenance.length > 0" class="bg-primary">
              <div class="card-header">
                <h4 class="text-center">
                  Maintenance ({{this.getUnit(mon.maintenance)}} Unit)
                </h4>
              </div>
              <div v-for="items in mon.maintenance" :key="items" class="bg-dark text-center">
                <div class="card-header">
                  <span class="text-center">
                    {{items.title}}
                  </span>
                </div>
                <div class="bg-white d-flex flex-wrap">
                  <div v-for="v in items.vehicles" :key="v" :class="'callout-item callout callout-danger mr-1 mt-1' + (isMore24(v.gps_position.time) ? ' bg-gray' : ' bg-success')" style="width:10vw;" @click.prevent="handleClick(v, $event)">
                    {{v.vehicle_no}}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="mon.parking && mon.parking.length > 0" class="bg-danger">
              <div class="card-header">
                <h4 class="text-center">
                  Parking ({{this.getUnit(mon.parking)}} Unit)
                </h4>
              </div>
              <div v-for="items in mon.parking" :key="items" class="bg-dark text-center">
                <div class="card-header">
                  <span class="text-center">
                    {{items.title}}
                  </span>
                </div>
                <div class="bg-white d-flex flex-wrap">
                  <div v-for="v in items.vehicles" :key="v" :class="'callout-item callout callout-success mr-1 mt-1' + (isMore24(v.gps_position.time) ? ' bg-gray' : ' bg-success')" style="width:10vw;" @click.prevent="handleClick(v, $event)">
                    {{v.vehicle_no}}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="mon.idling && mon.idling.length > 0" class="bg-warning">
              <div class="card-header">
                <h4 class="text-center">
                  Idling ({{this.getUnit(mon.idling)}} Unit)
                </h4>
              </div>
              <div v-for="items in mon.idling" :key="items" class="bg-dark text-center">
                <div class="card-header">
                  <span class="text-center">
                    {{items.title}}
                  </span>
                </div>
                <div class="bg-white d-flex flex-wrap">
                  <div v-for="v in items.vehicles" :key="v" :class="'callout-item callout callout-success mr-1 mt-1' + (isMore24(v.gps_position.time) ? ' bg-gray' : ' bg-success')" style="width:10vw;" @click.prevent="handleClick(v, $event)">
                    {{v.vehicle_no}}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="mon.driving && mon.driving.length > 0" class="bg-success">
              <div class="card-header">
                <h4 class="text-center">
                  Driving ({{this.getUnit(mon.driving)}} Unit)
                </h4>
              </div>
              <div v-for="items in mon.driving" :key="items" class="bg-dark text-center">
                <div class="card-header">
                  <span class="text-center">
                    {{items.title}}
                  </span>
                </div>
                <div class="bg-white d-flex flex-wrap">
                  <div v-for="v in items.vehicles" :key="v" :class="'callout-item callout callout-success mr-1 mt-1' + (isMore24(v.gps_position.time) ? ' bg-gray' : ' bg-success')" style="width:10vw;" @click.prevent="handleClick(v, $event)">
                    {{v.vehicle_no}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'  
  import Multiselect from '@vueform/multiselect'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('monitor_vehicles', ['monitor_vehicle', 'onRequest']),
        ...mapState('location_addresses', ['location_address']),
    },
    components: {
      Multiselect,
    },
    data(){
      return {
        accs: [],
        account_id: null,
        timer: null,
        visible: false,
        top: 0,
        left: 0,
        text: '',
        v: {},
        pageX: undefined,
        pageY: undefined,
        offsetY: undefined,
        offsetX: undefined,
      }
    },
    methods: {
      handleSubmit(){
        this.reloadData()
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('monitor_vehicles', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('location_addresses', {getLocation: 'get_data'}),
      reloadData(){
        this.clear()
        clearInterval(this.timer)
        if(this.account_id == null || this.account_id.length < 1){
          return;
        }
        let res = []
        this.account_id.map(item => {
          res.push(item)
        })
        this.get_data({account_id: res})
        const self = this;
        this.timer = setInterval(function(){
          self.reloadData()
        }, process.env.VUE_APP_RELOAD_TIME)
      },
      isMore24(time){
        return (moment(time).diff(moment(), 'days')) < 0
      },
      handleClick(item){
        this.v = item
        this.visible = true
        this.top = window.screen.height / 3 + 'px'
        this.left = window.screen.width / 3 + 'px'
        this.pageX = window.screen.width / 3
        this.pageY = window.screen.height / 3
        this.getLocation({lat: this.v.gps_position.lat, lon: this.v.gps_position.lng})
      },
      hideAlert(){
        this.visible = false
      },
      getTimeUnix(time){
        return moment.unix(time).format("lll")
      },
      getTime(time){
        return moment.utc(time).local().format("lll")
      },
      ondragend(event){
        if(event.clientY > 0 && event.clientX > 0){
          this.top = (event.clientY - this.offsetY) + 'px'
          this.left = (event.clientX - this.offsetX) + 'px'
          this.pageX = (event.clientX - this.offsetX)
          this.pageY = (event.clientY - this.offsetY)
        }
      },
      ondragstart(event){
        this.offsetX = event.clientX - this.pageX
        this.offsetY = event.clientY - this.pageY
      },
      getUnit(units){
        let total_unit = 0
        if(units){
          units.map(item => {
            if (item.vehicles)
              total_unit += item.vehicles.length
          })
        }
        return total_unit
      }
    },
    created() {
      this.$emit('onChildInit', 'Monitoring Vehicles')
      this.clear_data()
      this.get_all()
    },
    unmounted(){
      if(this.timer){
        clearInterval(this.timer)
      }
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accs = []
          nextState.map(item => {
            this.accs.push({valueProp: item.id, label: item.account_name})
          })
          return
        }
      },
      location_address(nextState, prevState){
        if(nextState !== prevState){
          if(this.v.gps_position){
            this.v.gps_position.address = nextState.address
          }
        }
      }
    },
  }
</script>
<style>
.callout-item {
  cursor: pointer;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
