<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>          
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="maintenance_schedules" :columns="columns" :title="'Trips'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('maintenance_schedules', ['maintenance_schedules', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),      
      columns (){
        return [
        {name: 'maintenance_name', text: 'Maintenance Name', order: 'asc'},
        {name: 'account', text: 'Account',
          raw: {
            type: 'Sub',
            value: 'account_name'
          }
        },
        {name: 'type', text: 'Type',
          raw: {
            type: 'Text',
            fields: [
              {value: '1', text: 'Service'},
              {value: '2', text: 'Inspection'},
              {value: '3', text: 'Repair'},
              {value: '4', text: 'Others'},
            ]
          }
        },
        {name: 'maintenance_type', text: 'Schedule Type',
          raw: {
            type: 'Text',
            fields: [
              {value: '1', text: 'After Certain KM Reached'},
              {value: '2', text: 'After Certain Month Passed'},
            ]
          }
        },
        {name: 'cost_estimation', text: 'Cost Estimation', class: 'text-right',
          raw: {
            type: 'format_number'
          }
        },
        {name: 'km_vehicle', text: 'KM Vehicle'},
        {name: 'schedule_month', text: 'Months'},
        {name: 'created_at', text: 'Created', raw:{type:'Moment_UTC'}},
        {name: 'action', text: 'Action',
          raw: {
            type: 'Action',
            fields: [
              getCapability('edit_maintenance_schedule', this.user) ? {event: 'edit_maintenance_schedule', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
              getCapability('delete_maintenance_schedule', this.user) ? {event: 'maintenance_schedules/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},              
            ]
          }
        }
      ]
      },
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('maintenance_schedules', ['get_maintenance_schedule_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id} = this
        this.get_maintenance_schedule_by_account({account_id})
      },
    },
    created() {
      this.$emit('onChildInit', 'Maintenance Schedules', getCapability('add_maintenance_schedule', this.user) ? '/maintenance_schedules/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
    }
  }
</script>
