<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="parent_id">Account:</label>
            <Select2 name="account_id" required v-model.number="account_id" :options="accountOptions" @change="onChange($event)" @select="onChange($event)"/>
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="terminal_id">Device:</label>
            <Select2 name="terminal_id" required v-model="terminal_id" :options="deviceOptions"/>
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-info" required type="submit" value="Stream Now" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('live_video_streamings', ['live_video_streaming', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
    },
    data(){
      return {
        account_id: null,
        terminal_id: null,
        accountOptions: [],
        deviceOptions: [],
      }
    },
    components:{
      Select2,
    },
    methods: {
      ...mapActions('live_video_streamings', ['get_data']),
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('accounts', ['get_all']),
      handleSubmit(){
        if(this.onRequest) return;
        this.reloadData()
      },
      reloadData(){
        const {terminal_id, live_video_streaming} = this
        if(terminal_id == null || live_video_streaming == null){
          return
        }
        let data = live_video_streaming.filter(item => item.terminal_id == terminal_id)
        if(data.length > 0){
          let url = data[0].url_live_streaming + "?tid="+data[0].terminal_id+"&sv="+data[0].url+"&ch="+data[0].channel+"&lp="+data[0].port_live_streaming
          window.open(url, '_blank');
        }
      },
      onChange(event){
        this.get_data({account_id: parseInt(event.id)})
        this.terminal_id = null
      },
    },
    created() {
      this.$emit('onChildInit', 'Live Video Streaming')
      this.get_all()
    },
    watch:{
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.account_id == null){
              this.account_id = this.accountOptions[0].id
              this.get_data({account_id: this.account_id})
            }
          }
          return
        }
      },
      live_video_streaming(nextState, prevState){
        if(nextState !== prevState){
          this.deviceOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.deviceOptions.push({id:item.terminal_id, text:item.vehicle_code + " - " + item.vehicle_no + " - " + item.terminal_id})
            })
          }
          return
        }
      },
    }
  }
</script>
