<template>
    <div class="row">
      <div v-if="visible" draggable="true" class="card" :style="'z-index:2000;cursor: move;position:fixed;top:'+this.top+';left:'+this.left" @dragend="ondragend($event)" @dragstart="ondragstart($event)">
        <div class="card-header">
          <h5 class="card-title">
            Vehicle Detail
          </h5>
          <button type="button" class="close float-right" @click="hideAlert()">×</button>
        </div>
        <div class="card-body">
          <div class="d-flex flex-row">
            <table class="table-bordered mr-1 table table-sm" v-for="v in checkpoints" :key="v" style="max-width:25rem">
              <tr><td>Status Arrival</td><th class="text-right">{{v.checkpoint.is_arrival == true ? 'Arriving' : 'Leaving'}}</th></tr>
              <tr v-if="v.checkpoint.next_stop_name"><td>Next Destination</td><th class="text-right">{{v.checkpoint.next_stop_name}}</th></tr>
              <tr v-if="v.checkpoint.next_stop_arrived_eta"><td>ETA</td><th class="text-right">{{moment(v.checkpoint.next_stop_arrived_eta)}}</th></tr>
              <tr><td>Vehicle No</td><th class="text-right">{{v.position.vehicle_no}}</th></tr>
              <tr><td>Vehicle Code</td><th class="text-right">{{v.position.vehicle_code}}</th></tr>
              <tr v-if="v.position_after && v.position"><td>Vehicle in Front</td><th class="text-right">{{v.position_after.vehicle_no + ' - ' + v.position_after.vehicle_code + ' (' +calculateDistance(v.position, v.position_after)+ ')'}}</th></tr>
              <tr v-if="v.position_before && v.position"><td>Vehicle Behind</td><th class="text-right">{{v.position_before.vehicle_no + ' - ' + v.position_before.vehicle_code + ' (' +calculateDistance(v.position, v.position_before)+ ')'}}</th></tr>
              <tr><td>Last Update</td><th class="text-right">{{getTime(v.position.gps_position.time)}}</th></tr>
              <tr><td>Status</td><th class="text-right">{{v.position.gps_position.online}}</th></tr>
              <tr><td>Speed</td><th class="text-right">{{v.position.gps_position.speed}} km/h</th></tr>
              <tr><td>Stop Duration</td><th class="text-right">{{v.position.gps_position.stop_duration}}</th></tr>
              <tr><td>Location</td><th class="text-right"><a target="_blank" :href="'https://maps.google.com/?q='+v.position.gps_position.lat+','+v.position.gps_position.lng">Google</a></th></tr>
              <tr v-for="sensor in v.position.gps_position.sensors" :key="sensor">
                <td>{{sensor.name}}</td><th class="text-right"><span v-html="sensor.value"></span></th>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="parent_id">Account:</label>
              <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="parent_id">Route:</label>
              <Select2 name="route_id" v-model.number="route_id" :options="routeOptions"/>
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-12">
        <div class="canvas">
          <div v-if="route_start && route_start.stop" :class="'start-item schematic-item shadow ' + (route_start.checkpoints.length > 0 ? 'schematic-green' : '')" @click="show_data(route_start.checkpoints, $event)">
            {{route_start.stop.stop_name}}
          </div>
          <div v-if="route_start && route_start.checkpoints2 && route_start.checkpoints2.length > 0" :class="'start-item-icon schematic-item '" :style="{left: (startmargintop) + 'px'}" @click="show_data(route_start.checkpoints2, $event)">
            <IconBusSvg :fill="'#3bb143'"/>
          </div>
          <div v-if="route_end && route_end.stop" :class="'end-item schematic-item shadow ' + (route_end.checkpoints.length > 0 ? 'schematic-green' : '')" :style="{left: maxWidth + 'px'}" @click="show_data(route_end.checkpoints, $event)">
            {{route_end.stop.stop_name}}
          </div>
          <div v-if="route_end && route_end.checkpoints2 && route_end.checkpoints2.length > 0" :class="'end-item-icon schematic-item '" :style="{left: (maxWidth) + 'px'}" @click="show_data(route_end.checkpoints2, $event)">
            <IconBusSvg :fill="'#3bb143'"/>
          </div>
          <div v-for="(item, index) in inbounds" :key="item" :class="'inbound-item schematic-item shadow '+ (item.checkpoints.length > 0 ? 'schematic-green' : '')" :style="{left: (startmargintop+(index+1)*(width+margintop)) + 'px'}" @click="show_data(item.checkpoints, $event)">
            {{item.stop.stop_name}}
          </div>
          <div v-for="(item, index) in inbounds" :key="item" class="schematic-item bus-item-top" :style="{left: (startmargintop+(index+1)*(width+margintop)) + 'px'}" @click="show_data(item.checkpoints, $event)">
            <IconBusStopSvg :class="(item.checkpoints.length > 0 ? 'clickable' : '')" :fill="(item.checkpoints.length > 0 ? '#3bb143' : '#555')"/>
          </div>
          <div v-for="(item, index) in inbounds" :key="item" class="schematic-item bus-item-top" :style="{left: (startmargintop2+(index+1)*(width+margintop)) + 'px'}" @click="show_data(item.checkpoints2, $event)">
            <IconBusSvg :class="(item.checkpoints2.length > 0 ? 'clickable' : '')" v-if="item.checkpoints2.length > 0" :fill="'#3bb143'"/>
          </div>
          <div v-if="outbounds.length > 0">
            <div v-for="(item, index) in outbounds" :key="item" :class="'outbound-item schematic-item shadow '+ (item.checkpoints.length > 0 ? 'schematic-green' : '')" :style="{left: (startmarginbottom+(index+1)*(width+marginbottom)) + 'px'}" @click="show_data(item.checkpoints, $event)">
              {{item.stop.stop_name}}
            </div>
            <div v-for="(item, index) in outbounds" :key="item" class="schematic-item bus-item-bottom" :style="{left: (startmarginbottom+(index+1)*(width+marginbottom)) + 'px'}" @click="show_data(item.checkpoints, $event)">
              <IconBusStopSvg :class="(item.checkpoints.length > 0 ? 'clickable' : '')" :fill="(item.checkpoints.length > 0 ? '#3bb143' : '#555')" :style="(item.checkpoints.length > 0 ? 'cursor: pointer;' : '')"/>
            </div>
            <div v-for="(item, index) in outbounds" :key="item" class="schematic-item bus-item-bottom" :style="{left: (startmarginbottom2+(index+1)*(width+marginbottom)) + 'px'}" @click="show_data(item.checkpoints2, $event)">
              <IconBusSvg :class="(item.checkpoints2.length > 0 ? 'clickable' : '')" v-if="item.checkpoints2.length > 0" :fill="'#3bb143'"/>
            </div>
          </div>
          <svg v-if="route_start.stop" height="500px" :width="(maxWidth + width)" style="background-color:white;">
            <line x1="50" y1="175" :x2="width" y2="75" style="stroke:rgb(255,0,0);stroke-width:2" />
            <line :x1="width" y1="75" :x2="(linewidth)" y2="75" style="stroke:rgb(255,0,0);stroke-width:2" />
            <line :x1="(linewidth)" y1="75" :x2="(linewidth) + 50" y2="175" style="stroke:rgb(255,0,0);stroke-width:2" />
            <line v-if="route_start.stop && this.outbounds.length > 0" x1="50" y1="175" :x2="width" y2="275" style="stroke:rgb(0,0,255);stroke-width:2" />
            <line v-if="route_start.stop && this.outbounds.length > 0" :x1="width" y1="275" :x2="(linewidth)" y2="275" style="stroke:rgb(0,0,255);stroke-width:2" />
            <line v-if="route_start.stop && this.outbounds.length > 0" :x1="(linewidth)" y1="275" :x2="(linewidth) + 50" y2="175" style="stroke:rgb(0,0,255);stroke-width:2" />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
    <GMapMap
    />
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import IconBusSvg from '../components/IconBusSvg.vue'
  import IconBusStopSvg from '../components/IconBusStopSvg.vue'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  import VueGoogleMaps from '@fawmi/vue-google-maps'
  import { formatNumber } from '../_helpers'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('routes', ['routes']),
        ...mapState('monitor_schematics', ['monitor_schematic', 'onRequest']),
        google : VueGoogleMaps
    },
    data(){
      return {
        route_id: '',
        account_id: '',
        inbounds: [],
        outbounds: [],
        route_start: {},
        route_end: {},
        width: 100,
        margintop: 10,
        maxWidth: 0,
        marginbottom: 10,
        startmargintop: 10,
        startmarginbottom: 10,
        startmargintop2: 10,
        startmarginbottom2: 10,
        linewidth: 0,
        timer: null,
        visible: false,
        top: 0,
        left: 0,
        checkpoints: [],
        offsetY: undefined,
        offsetX: undefined,
        pageX: undefined,
        pageY: undefined,
        accountOptions: [],
        routeOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.reloadData()
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
      ...mapActions('monitor_schematics', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.route_id = ''
      },
      reloadData(){
        this.clear()
        clearInterval(this.timer)
        if(this.route_id !== ''){
          this.get_data({route_id: this.route_id})
          const self = this;
          this.timer = setInterval(function(){
            self.reloadData()
          }, process.env.VUE_APP_RELOAD_TIME)
        }
      },
      getTime(time){
        return moment.utc(time).local().format("lll")
      },
      show_data(items){
        if(items.length > 0){
          this.checkpoints = items
          this.top = window.screen.height / 5 + 'px'
          this.left = window.screen.width / 5 + 'px'
          this.pageX = window.screen.width / 3
          this.pageY = window.screen.height / 3
          this.visible = true
        }
      },
      hideAlert(){
        this.visible = false
      },
      moment(time){
        return moment(time).format("HH:mm")
      },
      ondragend(event){
        if(event.clientY > 0 && event.clientX > 0){
          this.top = (event.clientY - this.offsetY) + 'px'
          this.left = (event.clientX - this.offsetX) + 'px'
          this.pageX = (event.clientX - this.offsetX)
          this.pageY = (event.clientY - this.offsetY)
        }
      },
      ondragstart(event){
        this.offsetX = event.clientX - this.pageX
        this.offsetY = event.clientY - this.pageY
      },
      calculateDistance(position, position2){
        if(!position || !position2 || !position.gps_position || !position2.gps_position){
          return ""
        }
        let from = new window.google.maps.LatLng(position.gps_position.lat, position.gps_position.lng)
        let to = new window.google.maps.LatLng(position2.gps_position.lat, position2.gps_position.lng)
        let res = window.google.maps.geometry.spherical.computeDistanceBetween(from, to)
        return formatNumber(res.toFixed(0)) + "m"
      }
    },
    created() {
      this.$emit('onChildInit', 'Monitoring Schematic')
      this.clear_data()
      this.get_all()
    },
    unmounted(){
      if(this.timer){
        clearInterval(this.timer)
      }
    },
    components: {
      IconBusSvg,
      IconBusStopSvg,
      Select2
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              if(item.status == 1){
                this.routeOptions.push({id:item.id, text:item.route_name + ' - ' + item.route_code})
              }
            })
          }
          return
        }
      },
      monitor_schematic (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.route && nextState.route.route_stops){
            const {route_stops} = nextState.route
            this.margintop = 10
            this.startmargintop = 10
            this.marginbottom = 10
            this.startmarginbottom = 10
            this.inbounds = route_stops.filter(item => item.bound === 'inbound')
            this.outbounds = route_stops.filter(item => item.bound === 'outbound')
            this.inbounds.sort((a,b) => (a.sequence > b.sequence) ? 1 : -1)
            this.outbounds.sort((a,b) => (a.sequence > b.sequence) ? 1 : -1)
            this.route_start = this.inbounds[0]
            this.route_end = this.inbounds[this.inbounds.length - 1]
            this.inbounds.splice(0, 1)
            this.inbounds.splice(this.inbounds.length - 1, 1)
            if(this.outbounds.length > 1){
              this.outbounds.splice(0, 1)
              this.outbounds.splice(this.outbounds.length - 1, 1)
              this.outbounds.reverse()
            }
            this.maxWidth = (this.startmargintop + (this.width + this.margintop) * Math.max(this.inbounds.length + 1, this.outbounds.length + 1))
            this.linewidth = this.maxWidth - this.margintop
            this.startmarginbottom2 = (10 - this.width) / 2
            this.startmargintop2 = 10 + (this.width + this.margintop) / 2
            if(this.outbounds.length > 1){
              if(this.outbounds.length > this.inbounds.length){
                this.maxWidth = (this.startmarginbottom + (this.width + this.marginbottom) * (this.outbounds.length + 1))
                this.margintop = (this.outbounds.length - this.inbounds.length) * this.width / this.inbounds.length
                this.linewidth = this.maxWidth - this.marginbottom
                this.startmarginbottom2 = (this.marginbottom - (this.width + this.marginbottom) / 2)
                this.startmargintop2 = (this.startmargintop + (this.width + this.margintop) / 2)
              } else if(this.outbounds.length < this.inbounds.length){
                this.marginbottom = (this.inbounds.length - this.outbounds.length) * this.width / this.outbounds.length
                this.startmarginbottom2 = (this.startmarginbottom - this.marginbottom * 2)
                this.startmargintop2 = (this.startmargintop + (this.width + this.margintop) / 2)
              }
            }
            for(let i = 0;i<this.inbounds.length;i++){
              this.inbounds[i].checkpoints = []
              this.inbounds[i].checkpoints2 = []
            }
            for(let i = 0;i<this.outbounds.length;i++){
              this.outbounds[i].checkpoints = []
              this.outbounds[i].checkpoints2 = []
            }
            this.route_start.checkpoints = []
            this.route_start.checkpoints2 = []
            this.route_end.checkpoints = []
            this.route_end.checkpoints2 = []

            if(nextState.checkpoints && nextState.checkpoints.length > 0){
              for(let j = 0;j<nextState.checkpoints.length;j++){
                let isFound = false
                if(this.route_start.stop_id == nextState.checkpoints[j].checkpoint.stop_id){
                  if(nextState.checkpoints[j].checkpoint.is_arrival == true)
                    this.route_start.checkpoints.push(nextState.checkpoints[j])
                  else
                    this.route_start.checkpoints2.push(nextState.checkpoints[j])
                  isFound = true
                  continue
                }
                if(this.route_end.stop_id == nextState.checkpoints[j].checkpoint.stop_id){
                  if(nextState.checkpoints[j].checkpoint.is_arrival == true)
                    this.route_end.checkpoints.push(nextState.checkpoints[j])
                  else
                    this.route_end.checkpoints2.push(nextState.checkpoints[j])
                  isFound = true
                  continue
                }
                for(let i = 0;i<this.inbounds.length;i++){
                  if(this.inbounds[i].stop_id == nextState.checkpoints[j].checkpoint.stop_id){
                    if(nextState.checkpoints[j].checkpoint.is_arrival == true)
                      this.inbounds[i].checkpoints.push(nextState.checkpoints[j])
                    else
                      this.inbounds[i].checkpoints2.push(nextState.checkpoints[j])
                    isFound = true
                    break
                  }
                }
                if(isFound == true){
                  continue
                }
                for(let i = 0;i<this.outbounds.length;i++){
                  if(this.outbounds[i].stop_id == nextState.checkpoints[j].checkpoint.stop_id){
                    if(nextState.checkpoints[j].checkpoint.is_arrival == true)
                      this.outbounds[i].checkpoints.push(nextState.checkpoints[j])
                    else
                      this.outbounds[i].checkpoints2.push(nextState.checkpoints[j])
                    isFound = true
                    break
                  }
                }
              }

              this.route_start = JSON.parse(JSON.stringify(this.route_start))
              this.route_end = JSON.parse(JSON.stringify(this.route_end))
              this.inbounds = JSON.parse(JSON.stringify(this.inbounds))
              this.outbounds = JSON.parse(JSON.stringify(this.outbounds))

              //ROUTE START
              for(let i = 0;i<this.route_start.checkpoints.length;i++){
                let found = false
                if(i<this.route_start.checkpoints.length - 1){
                  this.route_start.checkpoints[i].position_after = this.route_start.checkpoints[i + 1].position
                  this.route_start.checkpoints[i + 1].position_before = this.route_start.checkpoints[i].position
                  found = true
                }
                if(found){
                  continue
                }
                for(let j=0;j<this.route_start.checkpoints2.length;j++){
                  this.route_start.checkpoints[i].position_after = this.route_start.checkpoints2[j].position
                  this.route_start.checkpoints2[j].position_before = this.route_start.checkpoints[i].position
                  found = true
                  break
                }
                if(found){
                  continue
                }
                for(let j=0;j<this.inbounds.length;j++){
                  for(let k = 0;k<this.inbounds[j].checkpoints.length;k++){
                    this.route_start.checkpoints[i].position_after = this.inbounds[j].checkpoints[k].position
                    this.inbounds[j].checkpoints[k].position_before = this.route_start.checkpoints[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                  for(let k = 0;k<this.inbounds[j].checkpoints2.length;k++){
                    this.route_start.checkpoints[i].position_after = this.inbounds[j].checkpoints2[k].position
                    this.inbounds[j].checkpoints2[k].position_before = this.route_start.checkpoints[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                }
                if(found){
                  continue
                }
                for(let j = 0;j<this.route_end.checkpoints.length;j++){
                  this.route_start.checkpoints[i].position_after = this.route_end.checkpoints[j].position
                  this.route_end.checkpoints[j].position_before = this.route_start.checkpoints[i].position
                  found = true
                  break
                }
                if(found){
                  continue
                }                
                for(let j = 0;j<this.route_end.checkpoints2.length;j++){
                  this.route_start.checkpoints[i].position_after = this.route_end.checkpoints2[j].position
                  this.route_end.checkpoints2[j].position_before = this.route_start.checkpoints[i].position
                  found = true
                  break
                }
                if(found){
                  continue
                }                
                for(let j=0;j<this.outbounds.length;j++){
                  for(let k = 0;k<this.outbounds[j].checkpoints.length;k++){
                    this.route_start.checkpoints[i].position_after = this.outbounds[j].checkpoints[k].position
                    this.outbounds[j].checkpoints[k].position_before = this.route_start.checkpoints[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                  for(let k = 0;k<this.outbounds[j].checkpoints2.length;k++){
                    this.route_start.checkpoints[i].position_after = this.outbounds[j].checkpoints2[k].position
                    this.outbounds[j].checkpoints2[k].position_before = this.route_start.checkpoints[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                }
                if(found){
                  continue
                }
                this.route_start.checkpoints[i].position_after = null
              }

              for(let i = 0;i<this.route_start.checkpoints2.length;i++){
                let found = false
                if(i<this.route_start.checkpoints2.length - 1){
                  this.route_start.checkpoints2[i].position_after = this.route_start.checkpoints2[i + 1].position
                  this.route_start.checkpoints2[i + 1].position_before = this.route_start.checkpoints2[i].position
                  found = true
                }
                if(found){
                  continue
                }
                for(let j=0;j<this.inbounds.length;j++){
                  for(let k = 0;k<this.inbounds[j].checkpoints.length;k++){
                    this.route_start.checkpoints2[i].position_after = this.inbounds[j].checkpoints[k].position
                    this.inbounds[j].checkpoints[k].position_before = this.route_start.checkpoints2[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                  for(let k = 0;k<this.inbounds[j].checkpoints2.length;k++){
                    this.route_start.checkpoints2[i].position_after = this.inbounds[j].checkpoints2[k].position
                    this.inbounds[j].checkpoints2[k].position_before = this.route_start.checkpoints2[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                }
                if(found){
                  continue
                }
                for(let j = 0;j<this.route_end.checkpoints.length;j++){
                  this.route_start.checkpoints2[i].position_after = this.route_end.checkpoints[j].position
                  this.route_end.checkpoints[j].position_before = this.route_start.checkpoints2[i].position
                  found = true
                  break
                }
                if(found){
                  continue
                }                
                for(let j = 0;j<this.route_end.checkpoints2.length;j++){
                  this.route_start.checkpoints2[i].position_after = this.route_end.checkpoints2[j].position
                  this.route_end.checkpoints2[j].position_before = this.route_start.checkpoints2[i].position
                  found = true
                  break
                }
                if(found){
                  continue
                }                
                for(let j=0;j<this.outbounds.length;j++){
                  for(let k = 0;k<this.outbounds[j].checkpoints.length;k++){
                    this.route_start.checkpoints2[i].position_after = this.outbounds[j].checkpoints[k].position
                    this.outbounds[j].checkpoints[k].position_before = this.route_start.checkpoints2[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                  for(let k = 0;k<this.outbounds[j].checkpoints2.length;k++){
                    this.route_start.checkpoints2[i].position_after = this.outbounds[j].checkpoints2[k].position
                    this.outbounds[j].checkpoints2[k].position_before = this.route_start.checkpoints2[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                }
                if(found){
                  continue
                }
                this.route_start.checkpoints2[i].position_after = null
              }
              
              //INBOUNDS
              for(let i=0;i<this.inbounds.length;i++){                
                for(let j = 0;j<this.inbounds[i].checkpoints.length;j++){
                  let found = false
                  if(j<this.inbounds[i].checkpoints.length - 1){
                    this.inbounds[i].checkpoints[j].position_after = this.inbounds[i].checkpoints[j + 1].position
                    this.inbounds[i].checkpoints[j + 1].position_before = this.inbounds[i].checkpoints[j].position
                    found = true
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.inbounds[i].checkpoints2.length;k++){
                    this.inbounds[i].checkpoints[j].position_after = this.inbounds[i].checkpoints2[k].position
                    this.inbounds[i].checkpoints2[k].position_before = this.inbounds[i].checkpoints[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k = i+1;k<this.inbounds.length;k++){                    
                    for(let l = 0;l<this.inbounds[k].checkpoints.length;l++){
                      this.inbounds[i].checkpoints[j].position_after = this.inbounds[k].checkpoints[l].position
                      this.inbounds[k].checkpoints[l].position_before = this.inbounds[i].checkpoints[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                    for(let l = 0;l<this.inbounds[k].checkpoints2.length;l++){
                      this.inbounds[i].checkpoints[j].position_after = this.inbounds[k].checkpoints2[l].position
                      this.inbounds[k].checkpoints2[l].position_before = this.inbounds[i].checkpoints[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_end.checkpoints.length;k++){
                    this.inbounds[i].checkpoints[j].position_after = this.route_end.checkpoints[k].position
                    this.route_end.checkpoints[k].position_before = this.inbounds[i].checkpoints[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_end.checkpoints2.length;k++){
                    this.inbounds[i].checkpoints[j].position_after = this.route_end.checkpoints2[k].position
                    this.route_end.checkpoints2[k].position_before = this.inbounds[i].checkpoints[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k=this.outbounds.length-1;k>=0;k--){
                    for(let l = 0;l<this.outbounds[k].checkpoints.length;l++){
                      this.inbounds[i].checkpoints[j].position_after = this.outbounds[k].checkpoints[l].position
                      this.outbounds[k].checkpoints[l].position_before = this.inbounds[i].checkpoints[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                    for(let l = 0;l<this.outbounds[k].checkpoints2.length;l++){
                      this.inbounds[i].checkpoints[j].position_after = this.outbounds[k].checkpoints2[l].position
                      this.outbounds[k].checkpoints2[l].position_before = this.inbounds[i].checkpoints[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_start.checkpoints.length;k++){
                    this.inbounds[i].checkpoints[j].position_after = this.route_start.checkpoints[k].position
                    this.route_start.checkpoints[k].position_before = this.inbounds[i].checkpoints[j].position
                    found = true
                    break
                  }
                  for(let k = 0;k<this.route_start.checkpoints2.length;k++){
                    this.inbounds[i].checkpoints[j].position_after = this.route_start.checkpoints2[k].position
                    this.route_start.checkpoints2[k].position_before = this.inbounds[i].checkpoints[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  this.inbounds[i].checkpoints[j].position_after = null
                }
                for(let j = 0;j<this.inbounds[i].checkpoints2.length;j++){
                  let found = false
                  if(j<this.inbounds[i].checkpoints2.length - 1){
                    this.inbounds[i].checkpoints2[j].position_after = this.inbounds[i].checkpoints2[j + 1].position
                    this.inbounds[i].checkpoints2[j + 1].position_before = this.inbounds[i].checkpoints2[j].position
                    found = true
                  }
                  if(found){
                    continue
                  }
                  for(let k = i+1;k<this.inbounds.length;k++){                    
                    for(let l = 0;l<this.inbounds[k].checkpoints.length;l++){
                      this.inbounds[i].checkpoints2[j].position_after = this.inbounds[k].checkpoints[l].position
                      this.inbounds[k].checkpoints[l].position_before = this.inbounds[i].checkpoints2[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                    for(let l = 0;l<this.inbounds[k].checkpoints2.length;l++){
                      this.inbounds[i].checkpoints2[j].position_after = this.inbounds[k].checkpoints2[l].position
                      this.inbounds[k].checkpoints2[l].position_before = this.inbounds[i].checkpoints2[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_end.checkpoints.length;k++){
                    this.inbounds[i].checkpoints2[j].position_after = this.route_end.checkpoints[k].position
                    this.route_end.checkpoints[k].position_before = this.inbounds[i].checkpoints2[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_end.checkpoints2.length;k++){
                    this.inbounds[i].checkpoints2[j].position_after = this.route_end.checkpoints2[k].position
                    this.route_end.checkpoints2[k].position_before = this.inbounds[i].checkpoints2[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k=this.outbounds.length - 1;k>=0;k--){
                    for(let l = 0;l<this.outbounds[k].checkpoints.length;l++){
                      this.inbounds[i].checkpoints2[j].position_after = this.outbounds[k].checkpoints[l].position
                      this.outbounds[k].checkpoints[l].position_before = this.inbounds[i].checkpoints2[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                    for(let l = 0;l<this.outbounds[k].checkpoints2.length;l++){
                      this.inbounds[i].checkpoints2[j].position_after = this.outbounds[k].checkpoints2[l].position
                      this.outbounds[k].checkpoints2[l].position_before = this.inbounds[i].checkpoints2[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_start.checkpoints.length;k++){
                    this.inbounds[i].checkpoints2[j].position_after = this.route_start.checkpoints[k].position
                    this.route_start.checkpoints[k].position_before = this.inbounds[i].checkpoints2[j].position
                    found = true
                    break
                  }
                  for(let k = 0;k<this.route_start.checkpoints2.length;k++){
                    this.inbounds[i].checkpoints2[j].position_after = this.route_start.checkpoints2[k].position
                    this.route_start.checkpoints2[k].position_before = this.inbounds[i].checkpoints2[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  this.inbounds[i].checkpoints2[j].position_after = null
                }
              }

              //ROUTE END
              for(let i = 0;i<this.route_end.checkpoints.length;i++){
                let found = false
                if(i<this.route_end.checkpoints.length - 1){
                  this.route_end.checkpoints[i].position_after = this.route_end.checkpoints[i + 1].position
                  this.route_end.checkpoints[i + 1].position_before = this.route_end.checkpoints[i].position
                  found = true
                }
                if(found){
                  continue
                }
                for(let j=0;j<this.route_end.checkpoints2.length;j++){
                  this.route_end.checkpoints[i].position_after = this.route_end.checkpoints2[j].position
                  this.route_end.checkpoints2[j].position_before = this.route_end.checkpoints[i].position
                  found = true
                  break
                }
                if(found){
                  continue
                }            
                for(let j=this.outbounds.length-1;j>=0;j--){
                  for(let k = 0;k<this.outbounds[j].checkpoints.length;k++){
                    this.route_end.checkpoints[i].position_after = this.outbounds[j].checkpoints[k].position
                    this.outbounds[j].checkpoints[k].position_before = this.route_end.checkpoints[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                  for(let k = 0;k<this.outbounds[j].checkpoints2.length;k++){
                    this.route_end.checkpoints[i].position_after = this.outbounds[j].checkpoints2[k].position
                    this.outbounds[j].checkpoints2[k].position_before = this.route_end.checkpoints[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                }
                if(found){
                  continue
                }

                for(let j = 0;j<this.route_start.checkpoints.length;j++){
                  this.route_end.checkpoints[i].position_after = this.route_start.checkpoints[j].position
                  this.route_start.checkpoints[j].position_before = this.route_end.checkpoints[i].position
                  found = true
                  break
                }
                if(found){
                  continue
                }                
                for(let j = 0;j<this.route_start.checkpoints2.length;j++){
                  this.route_end.checkpoints[i].position_after = this.route_start.checkpoints2[j].position
                  this.route_start.checkpoints2[j].position_before = this.route_end.checkpoints[i].position
                  found = true
                  break
                }
                if(found){
                  continue
                }                 
                for(let j=0;j<this.inbounds.length;j++){
                  for(let k = 0;k<this.inbounds[j].checkpoints.length;k++){
                    this.route_end.checkpoints[i].position_after = this.inbounds[j].checkpoints[k].position
                    this.inbounds[j].checkpoints[k].position_before = this.route_end.checkpoints[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                  for(let k = 0;k<this.inbounds[j].checkpoints2.length;k++){
                    this.route_end.checkpoints[i].position_after = this.inbounds[j].checkpoints2[k].position
                    this.inbounds[j].checkpoints2[k].position_before = this.route_end.checkpoints[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                }
                if(found){
                  continue
                }   
                this.route_end.checkpoints[i].position_after = null
              }

              for(let i = 0;i<this.route_end.checkpoints2.length;i++){
                let found = false
                if(i<this.route_end.checkpoints2.length - 1){
                  this.route_end.checkpoints2[i].position_after = this.route_end.checkpoints2[i + 1].position
                  this.route_end.checkpoints2[i + 1].position_before = this.route_end.checkpoints2[i].position
                  found = true
                }
                if(found){
                  continue
                }          
                for(let j=this.outbounds.length-1;j>=0;j--){
                  for(let k = 0;k<this.outbounds[j].checkpoints.length;k++){
                    this.route_end.checkpoints2[i].position_after = this.outbounds[j].checkpoints[k].position
                    this.outbounds[j].checkpoints[k].position_before = this.route_end.checkpoints2[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                  for(let k = 0;k<this.outbounds[j].checkpoints2.length;k++){
                    this.route_end.checkpoints2[i].position_after = this.outbounds[j].checkpoints2[k].position
                    this.outbounds[j].checkpoints2[k].position_before = this.route_end.checkpoints2[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                }
                if(found){
                  continue
                }

                for(let j = 0;j<this.route_start.checkpoints.length;j++){
                  this.route_end.checkpoints2[i].position_after = this.route_start.checkpoints[j].position
                  this.route_start.checkpoints[j].position_before = this.route_end.checkpoints2[i].position
                  found = true
                  break
                }
                if(found){
                  continue
                }                
                for(let j = 0;j<this.route_start.checkpoints2.length;j++){
                  this.route_end.checkpoints2[i].position_after = this.route_start.checkpoints2[j].position
                  this.route_start.checkpoints2[j].position_before = this.route_end.checkpoints2[i].position
                  found = true
                  break
                }
                if(found){
                  continue
                }                 
                for(let j=0;j<this.inbounds.length;j++){
                  for(let k = 0;k<this.inbounds[j].checkpoints.length;k++){
                    this.route_end.checkpoints2[i].position_after = this.inbounds[j].checkpoints[k].position
                    this.inbounds[j].checkpoints[k].position_before = this.route_end.checkpoints2[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                  for(let k = 0;k<this.inbounds[j].checkpoints2.length;k++){
                    this.route_end.checkpoints2[i].position_after = this.inbounds[j].checkpoints2[k].position
                    this.inbounds[j].checkpoints2[k].position_before = this.route_end.checkpoints2[i].position
                    found = true
                    break
                  }
                  if(found){
                    break
                  }
                }
                if(found){
                  continue
                }   
                this.route_end.checkpoints2[i].position_after = null
              }

              //OUTBOUNDS
              for(let i=this.outbounds.length - 1;i>=0;i--){                
                for(let j = 0;j<this.outbounds[i].checkpoints.length;j++){
                  let found = false
                  if(j<this.outbounds[i].checkpoints.length - 1){
                    this.outbounds[i].checkpoints[j].position_after = this.outbounds[i].checkpoints[j + 1].position
                    this.outbounds[i].checkpoints[j + 1].position_before = this.outbounds[i].checkpoints[j].position
                    found = true
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.outbounds[i].checkpoints2.length;k++){
                    this.outbounds[i].checkpoints[j].position_after = this.outbounds[i].checkpoints2[k].position
                    this.outbounds[i].checkpoints2[k].position_before = this.outbounds[i].checkpoints[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }                  
                  for(let k = i-1;k>=0;k--){                    
                    for(let l = 0;l<this.outbounds[k].checkpoints.length;l++){
                      this.outbounds[i].checkpoints[j].position_after = this.outbounds[k].checkpoints[l].position
                      this.outbounds[k].checkpoints[l].position_before = this.outbounds[i].checkpoints[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                    for(let l = 0;l<this.outbounds[k].checkpoints2.length;l++){
                      this.outbounds[i].checkpoints[j].position_after = this.outbounds[k].checkpoints2[l].position
                      this.outbounds[k].checkpoints2[l].position_before = this.outbounds[i].checkpoints[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                  }
                  for(let k = 0;k<this.route_start.checkpoints.length;k++){
                    this.outbounds[i].checkpoints[j].position_after = this.route_start.checkpoints[k].position
                    this.route_start.checkpoints[k].position_before = this.outbounds[i].checkpoints[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_start.checkpoints2.length;k++){
                    this.outbounds[i].checkpoints[j].position_after = this.route_start.checkpoints2[k].position
                    this.route_start.checkpoints2[k].position_before = this.outbounds[i].checkpoints[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k=0;k<this.inbounds.length;k++){
                    for(let l = 0;l<this.inbounds[k].checkpoints.length;l++){
                      this.outbounds[i].checkpoints[j].position_after = this.inbounds[k].checkpoints[l].position
                      this.inbounds[k].checkpoints[l].position_before = this.outbounds[i].checkpoints[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                    for(let l = 0;l<this.inbounds[k].checkpoints2.length;l++){
                      this.outbounds[i].checkpoints[j].position_after = this.inbounds[k].checkpoints2[l].position
                      this.inbounds[k].checkpoints2[l].position_before = this.outbounds[i].checkpoints[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_end.checkpoints.length;k++){
                    this.outbounds[i].checkpoints[j].position_after = this.route_end.checkpoints[k].position
                    this.route_end.checkpoints[k].position_before = this.outbounds[i].checkpoints[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_end.checkpoints2.length;k++){
                    this.outbounds[i].checkpoints[j].position_after = this.route_end.checkpoints2[k].position
                    this.route_end.checkpoints2[k].position_before = this.outbounds[i].checkpoints[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  this.outbounds[i].checkpoints[j].position_after = null
                }
                for(let j = 0;j<this.outbounds[i].checkpoints2.length;j++){
                  let found = false
                  if(j<this.outbounds[i].checkpoints2.length - 1){
                    this.outbounds[i].checkpoints2[j].position_after = this.outbounds[i].checkpoints2[j + 1].position
                    this.outbounds[i].checkpoints2[j + 1].position_before = this.outbounds[i].checkpoints2[j].position
                    found = true
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.outbounds[i].checkpoints.length;k++){
                    this.outbounds[i].checkpoints2[j].position_after = this.outbounds[i].checkpoints[k].position
                    this.outbounds[i].checkpoints[k].position_before = this.outbounds[i].checkpoints2[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k = i-1;k>=0;k--){                    
                    for(let l = 0;l<this.outbounds[k].checkpoints.length;l++){
                      this.outbounds[i].checkpoints2[j].position_after = this.outbounds[k].checkpoints[l].position
                      this.outbounds[k].checkpoints[l].position_before = this.outbounds[i].checkpoints2[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                    for(let l = 0;l<this.outbounds[k].checkpoints2.length;l++){
                      this.outbounds[i].checkpoints2[j].position_after = this.outbounds[k].checkpoints2[l].position
                      this.outbounds[k].checkpoints2[l].position_before = this.outbounds[i].checkpoints2[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_start.checkpoints.length;k++){
                    this.outbounds[i].checkpoints2[j].position_after = this.route_start.checkpoints[k].position
                    this.route_start.checkpoints[k].position_before = this.outbounds[i].checkpoints2[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_start.checkpoints2.length;k++){
                    this.outbounds[i].checkpoints2[j].position_after = this.route_start.checkpoints2[k].position
                    this.route_start.checkpoints2[k].position_before = this.outbounds[i].checkpoints2[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k=0;k<this.inbounds.length;k++){
                    for(let l = 0;l<this.inbounds[k].checkpoints.length;l++){
                      this.outbounds[i].checkpoints2[j].position_after = this.inbounds[k].checkpoints[l].position
                      this.inbounds[k].checkpoints[l].position_before = this.outbounds[i].checkpoints2[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                    for(let l = 0;l<this.inbounds[k].checkpoints2.length;l++){
                      this.outbounds[i].checkpoints2[j].position_after = this.inbounds[k].checkpoints2[l].position
                      this.inbounds[k].checkpoints2[l].position_before = this.outbounds[i].checkpoints2[j].position
                      found = true
                      break
                    }
                    if(found){
                      break
                    }
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_end.checkpoints.length;k++){
                    this.outbounds[i].checkpoints2[j].position_after = this.route_end.checkpoints[k].position
                    this.route_end.checkpoints[k].position_before = this.outbounds[i].checkpoints2[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  for(let k = 0;k<this.route_end.checkpoints2.length;k++){
                    this.outbounds[i].checkpoints2[j].position_after = this.route_end.checkpoints2[k].position
                    this.route_end.checkpoints2[k].position_before = this.outbounds[i].checkpoints2[j].position
                    found = true
                    break
                  }
                  if(found){
                    continue
                  }
                  this.outbounds[i].checkpoints2[j].position_after = null
                }
              }
            }
          }
          return
        }
      }
    },
  }
</script>
<style>
  .canvas {
    height: 500px;
    border-top: 1px solid black;
    padding-top: 20px;
    background-color: white;
  }
 .schematic-item {
   color:white;
   width: 100px;
   height:50px;
   justify-content: center;
   align-items: center;
   display: flex;
   word-wrap: normal;
   white-space:pre-wrap;
   overflow-x: hidden;
   position:absolute;
   text-align: center;
   z-index: 1;
 }
 .inbound-item{
   background-color: red;
 }
 .outbound-item{
   background-color: blue;
   top: 325px;
 }
 .start-item{
   background-color: #d2691e;
   color: black;
   top: 170px;
 }
 .start-item-icon{
   top: 87px;
   cursor: pointer;
 }
 .end-item{
   background-color: #ba55d3;
   top: 170px;
 }
 .end-item-icon{
   top: 255px;
   cursor: pointer;
 }
 .bus-item-top{
   top: 87px;
 }
 .bus-item-bottom{
   top: 255px;
 }
 .schematic-green{
   background-color: #3bb143 !important;
   cursor: pointer;
   color: white;
 }
 .clickable{
   cursor: pointer;
 }
</style>
