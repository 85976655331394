<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-4 required">
            <label for="from_date">From Date:</label>
            <input class="form-control" required type="date" v-model="from_date">
          </div>
          <div class="form-group col-sm-12 col-md-4 required">
            <label for="to_date">To Date:</label>
            <input class="form-control" type="date" v-model="to_date">
          </div>
          <div class="form-group col-sm-12 col-md-4 required">
            <label for="limit">Limit:</label>
            <input class="form-control" type="number" v-model.number="limit" required min="1">
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="custom_event_alerts" :columns="columns" :currEntries="100" :title="'Monitoring Custom Event Alerts'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import moment from 'moment'
  import { getCapability } from '../_helpers'
  export default {
    computed: {
      ...mapState('custom_event_alerts', ['custom_event_alerts', 'onRequest']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'vehicle', text: 'Vehicle No',
              raw: {
                type: 'Sub',
                value: 'vehicle_no'
              }
            },
            {name: 'vehicle', text: 'Vehicle Code',
              raw: {
                type: 'Sub',
                value: 'vehicle_code'
              }
            },
            {name: 'account', text: 'Account',
              raw: {
                type: 'Sub',
                value: 'account_name'
              }
            },
            {name: 'driver', text: 'Driver',
              raw: {
                type: 'Sub',
                value: 'name'
              }
            },
            {name: 'created_at', text: 'GPS Time', order: 'desc', raw:{
              type: 'Moment_UTC'
            }},
            {name: 'message', text: 'Message', class:'text-danger'},
            {text: 'Location', raw:{
              type: 'gps_lat_lng'
            }},
            {name: 'status', text: 'Status',
              raw: {
                type: 'Text',
                fields: [
                  {value: '1', text: 'New', class: 'text-danger'},
                  {value: '2', text: 'Case Created', class: 'text-warning'},
                  {value: '3', text: 'Closed', class: 'text-success'},
                ]
              }
            },
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('edit_custom_event_alert', this.user) ? {event: 'edit_custom_event_alert', text: 'Create Case', method: 'get', class: 'btn btn-danger', check: {
                    name: 'status', value: 1
                  }} : {},
                ]
              }
            },
          ]
      }
    },
    data(){
      return {
        timer: null,
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        limit: 100
      }
    },
    components:{
      Datatable,
    },
    unmounted(){
      if(this.timer){
        clearInterval(this.timer)
      }
    },
    methods: {
      ...mapActions('custom_event_alerts', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }
        this.reloadData()
      },
      reloadData(){
        this.clear()
        clearInterval(this.timer)
        this.get_data({from_date: this.from_date, to_date: this.to_date, limit: this.limit})
        const self = this;
        this.timer = setInterval(function(){
          self.reloadData()
        }, process.env.VUE_APP_RELOAD_TIME)
      },
    },
    created() {
      this.$emit('onChildInit', 'Monitoring Custom Event Alerts')
      this.clear_data()
      this.get_data({from_date: this.from_date, to_date: this.to_date})
    },
    watch:{
      custom_event_alerts(nextState){
        for(let i = 0;i<nextState.length;i++){
          let item = nextState[i];
          if(item.status > 1) continue;
          if(moment(item.created_at).diff(moment(), 'seconds') > (-process.env.VUE_APP_RELOAD_TIME / 1000)){
            var audio = new Audio(require('../assets/alarm.wav'));
            audio.play();
            break
          }
        }
      }
    }
  }
</script>
