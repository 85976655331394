<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>          
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="maintenance_reminders" :columns="columns" :title="'Trips'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('maintenance_reminders', ['maintenance_reminders', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),      
      columns (){
        return [
        {name: 'vehicle', text: 'Vehicle No', order: 'asc',
          raw: {
            type: 'Sub',
            value: 'vehicle_no'
          }
        },
        {name: 'vehicle', text: 'Vehicle Code',
          raw: {
            type: 'Sub',
            value: 'vehicle_code'
          }
        },
        {name: 'vehicle', text: 'Current KM', class: 'text-right',
          raw: {
            type: 'Sub_format_number',
            value: 'odometer'
          }
        },
        {name: 'maintenance_schedule', text: 'Maintenance Name',
          raw: {
            type: 'Sub',
            value: 'maintenance_name'
          }
        },
        {name: 'maintenance_schedule', text: 'Type',
          raw: {
            type: 'Sub_text',
            value: 'type',
            fields: [
              {value: '1', text: 'Service'},
              {value: '2', text: 'Inspection'},
              {value: '3', text: 'Repair'},
              {value: '4', text: 'Others'},
            ]
          }
        },
        {name: 'maintenance_schedule', text: 'Schedule Type',
          raw: {
            type: 'Sub_text',
            value: 'maintenance_type',
            fields: [
              {value: '1', text: 'After Certain KM Reached'},
              {value: '2', text: 'After Certain Month Passed'},
            ]
          }
        },
        {name: 'maintenance_schedule', text: 'KM Vehicle', class: 'text-right text-danger',
          raw: {
            type: 'Sub_format_number',
            value: 'km_vehicle'
          }
        },
        {name: 'maintenance_schedule', text: 'Months', class: 'text-right text-danger',
          raw: {
            type: 'Sub_format_number',
            value: 'schedule_month'
          }
        },
        {name: 'maintenance_schedule', text: 'Cost Estimation', class: 'text-right',
          raw: {
            type: 'Sub_format_number',
            value: 'cost_estimation'
          }
        },
        {name: 'latest_work_order', text: 'Latest Work Order Code',
          raw: {
            type: 'Sub',
            value: 'work_order_code'
          }
        },
        {name: 'latest_work_order', text: 'Latest Work Order Date',
          raw: {
            type: 'Sub_Moment_UTC',
            value: 'start'
          }
        },
        {name: 'latest_work_order', text: 'Latest Work Order KM', class: 'text-right',
          raw: {
            type: 'Sub_format_number',
            value: 'km_vehicle'
          }
        },
      ]
      },
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('maintenance_reminders', ['get_maintenance_reminder_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id} = this
        this.get_maintenance_reminder_by_account({account_id})
      },
    },
    created() {
      this.$emit('onChildInit', 'Maintenance Reminders')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
    }
  }
</script>
