<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="parent_id">Account:</label>
            <Select2 name="account_id" required v-model.number="account_id" :options="accountOptions"/>
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="ceiba_server_id">Live Video Server:</label>
            <Select2 name="ceiba_server_id" required v-model.number="ceiba_server_id" :options="ceibaServerOptions"/>
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="count">Show:</label>
            <input class="form-control" name="count" required type="number" v-model.number="count" min="10" max="50">
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="live_video_alert" :columns="columns" :currEntries="100" :title="'Monitoring Video Alerts'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('live_video_alerts', ['live_video_alert', 'onRequest']),
      ...mapState('ceiba_servers', ['ceiba_servers']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'createtime', text: 'Time', order: 'desc', raw:{
              type: 'Moment'
            }},
            {name: 'ename', text: 'Event Name'},
            {name: 'terid', text: 'Terminal ID'},
            {name: 'vehicle_no', text: 'Vehicle No'},
            {name: 'vehicle_code', text: 'Vehicle Code'},           
            {name: 'size', text: 'Size (MB)', class:"text-right"},
            {name: 'sec', text: 'Duration (sec)', class:"text-right"},
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  {url: 'path', text: 'View', method: 'open_newtab', class: 'btn btn-info ml-1'},
                ]
              }
            },
          ]
      }
    },
    data(){
      return {
        ceiba_server_id: null,
        account_id: null,
        count: 50,
        accountOptions: [],
        ceibaServerOptions: [],
        timer: null,
      }
    },
    components:{
      Datatable,
      Select2,
    },
    unmounted(){
      if(this.timer){
        clearInterval(this.timer)
      }
    },
    methods: {
      ...mapActions('live_video_alerts', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('ceiba_servers', {getCeibaServers:'get_all'}),
      handleSubmit(){
        if(this.onRequest) return;
        this.reloadData()
      },
      reloadData(){
        this.clear()
        clearInterval(this.timer)
        const {ceiba_server_id, account_id, count} = this
        if(account_id == null || ceiba_server_id == null){
          return;
        }
        this.get_data({ceiba_server_id, account_id, count})
        const self = this;
        this.timer = setInterval(function(){
          self.reloadData()
        }, process.env.VUE_APP_RELOAD_TIME)
      },
    },
    created() {
      this.$emit('onChildInit', 'Monitoring Video Alerts')
      this.clear_data()
      this.getCeibaServers()
      this.get_all()
    },
    watch:{
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.account_id == null){
              this.account_id = this.accountOptions[0].id
              this.reloadData()
            }
          }
          return
        }
      },
      ceiba_servers(nextState, prevState){
        if(nextState !== prevState){
          this.ceibaServerOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.ceibaServerOptions.push({id:item.id, text:item.server_name})
            })
            if(this.ceiba_server_id == null){
              this.ceiba_server_id = this.ceibaServerOptions[0].id
              this.reloadData()
            }
          }
          return
        }
      }
    }
  }
</script>
