<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" required v-model.number="maintenance_schedule.account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>   
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Maintenance Name:</label>
        <input class="form-control" required="" name="maintenance_name" type="text" v-model="maintenance_schedule.maintenance_name">
      </div>   
      <div class="form-group col-sm-12 required">
        <label for="vehicle_id">Vehicle:</label>
        <Multiselect mode="multiple" required v-model="value_multiple_vehicle" :options="vehicleOptions" valueProp="valueProp" label="label" :searchable="true">
          <template v-slot:multiplelabel="{ values }">
            <div class="multiselect-multiple-label">
              <span v-for="(item2, index2) in values" :key="index2">{{item2.label}},&nbsp;&nbsp;</span>
            </div>
          </template>
        </Multiselect>
      </div>
      <div class="form-group col-sm-12 col-md-4 required">
        <label for="type">Type:</label>
        <select class="form-control" name="type" v-model.number="maintenance_schedule.type">
          <option value="1" style="color:black;">Service</option>
          <option value="2" style="color:black;">Inspection</option>
          <option value="3" style="color:black;">Repair / Accident</option>
          <option value="4" style="color:black;">Others</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-4 required">
        <label for="status">Schedule Type:</label>
        <select class="form-control" name="status" v-model.number="maintenance_schedule.maintenance_type">
          <option value="1" style="color:black;">After Certain KM Reached</option>
          <option value="2" style="color:black;">After Certain Month Passed</option>
        </select>
      </div>
      <div v-if="maintenance_schedule.maintenance_type == 1" class="form-group col-sm-12 col-md-4 required">
        <label for="status">KM Vehicle:</label>
        <input class="form-control" name="maintenance_name" type="number" v-model.number="maintenance_schedule.km_vehicle" step="any" min="0" required>
      </div>
      <div v-else class="form-group col-sm-12 col-md-4 required">
        <label for="status">Month Passed Since Last Maintenance:</label>
        <input class="form-control" name="maintenance_name" type="number" v-model.number="maintenance_schedule.schedule_month" min="1" max="12" required>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="cost_estimation">Cost Estimation (Part Usage + Additional Cost):</label>
        <input class="form-control" name="cost_estimation" type="number" v-model.number="maintenance_schedule.cost_estimation" required>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/maintenance_schedules">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  import Multiselect from '@vueform/multiselect'
  export default {
    computed: {
      ...mapState('accounts', ['accounts']),
      ...mapState('vehicles', ['vehicles']),
      ...mapState('maintenance_schedules', ['onRequest']),
    },
    components:{
      Select2,
      Multiselect,
    },
    data(){
      return {
        maintenance_schedule: {
          maintenance_name: '',
          account_id: '',
          km_vehicle: null,
          schedule_month: null,
          cost_estimation: null,
          maintenance_type: 1,
          type: 1,
          maintenance_schedule_vehicle:[],
        },
        value_multiple_vehicle: null,
        accountOptions: [],
        vehicleOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        let res = []
        this.value_multiple_vehicle.map(item => {
          res.push({vehicle_id: item})
        })
        this.maintenance_schedule.maintenance_schedule_vehicle = res
        this.add(this.maintenance_schedule)
      },
      ...mapActions('maintenance_schedules', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      onChangeAccount(event){
        this.getVehicles({account_id: parseInt(event.id)})
        this.value_multiple_vehicle = null
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Maintenance Schedule')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({valueProp:item.id, label:item.vehicle_no + ' - ' + item.vehicle_code})
            })
          }
          return
        }
      },
    }
  }
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
