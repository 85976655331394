<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-sm-12">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="parent_id">Account:</label>
              <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"  @change="onChange($event)" @select="onChange($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="deviation">Off Route Deviation:</label>
              <select class="form-control" required v-model.number="deviation">
                <option v-for="devi in deviations" :key="devi" :value="devi.value">{{devi.label}}</option>
              </select>
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
            <div class="form-group col-sm-12 col-md-12">
              <label for="route_ids">Route:</label>
              <Multiselect mode="multiple" v-model="route_ids" :options="routeOptions" valueProp="valueProp" label="label" :searchable="true" @change="onChangeRoute($event)">
                <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <span v-for="(item2, index2) in values" :key="index2">{{item2.label}},&nbsp;&nbsp;</span>
                  </div>
                </template>
              </Multiselect>
            </div>
            <div class="form-group col-sm-6 col-md-4">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="show_traffic_checkbox"
                  v-model="show_traffic">
                <label class="custom-control-label" for="show_traffic_checkbox">Show Traffic</label>
              </div>
            </div>
            <div class="form-group col-sm-6 col-md-4">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="show_stop_checkbox"
                  v-model="show_stop">
                <label class="custom-control-label" for="show_stop_checkbox">Show Stop Bus</label>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <GMapMap
            :center="center"
            :zoom="14"
            :disableDefaultUI="true"
            :options="{
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: true,
                  streetViewControl: false,
                  rotateControl: true,
                  fullscreenControl: true,
            }"
            ref="gmap"
            map-type-id="terrain"
            style="width: 100%; height: 60vh"
        >
          <div v-if="show_stop">
            <MapStopComponent
              v-for="stop in stops"
              :key="stop"
              :isOpen="false"
              :gmap="this.$refs.gmap"
              :stop="stop.stop"/>
          </div>
          <GMapPolyline 
            v-for="r in data_routes"
            :key="r"
            :path="r.route_points"
            :options="{ strokeColor:r.route_color, strokeWeight: 2}"
            />
          <MapVehicleComponent
            v-for="vehicle in data"
            :key="vehicle"
            :selected_vehicle="selected_vehicle"
            :selectVehicle="selectVehicle"
            :gps_position="vehicle.gps_position"
            :gmap="this.$refs.gmap"
            :vehicle="vehicle"/>
        </GMapMap>
      </div>
    </div>
    <div v-if="data.length > 0" class="row mt-3">
      <div class="col-sm-12 col-md-12">
        <Datatable v-bind:entries="data" :columns="columns" :title="'Monitor Route Vehicle'" :currEntries="100" :onRowClickEvent="onRowClicked"/>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import VueGoogleMaps from '@fawmi/vue-google-maps'
  import MapStopComponent from '../components/MapStopComponent.vue'
  import MapVehicleComponent from '../components/MapVehicleComponent.vue'
  import Datatable from '../components/Datatable.vue'
  import {calculateDeviation} from '../_helpers'
  import Select2 from 'vue3-select2-component';
  import Multiselect from '@vueform/multiselect'
  export default {
    computed: {
      ...mapState('accounts', ['accounts']),
      ...mapState('routes', ['routes']),
      ...mapState('monitor_route_vehicles', ['monitor_route_vehicle', 'onRequest']),
      google: VueGoogleMaps,
      columns(){
        return [
          {name: 'vehicle_no', text: 'Vehicle No'},
          {name: 'vehicle_code', text: 'Vehicle Code'},
          {name: 'driver', text: 'Driver'},
          {name: 'gps_position', text: 'Speed', class:'text-right', raw:{
            type: 'Sub',
            value: 'speed'
          }},
          {name: 'status', text: 'Status'},
        ]
      }
    },
    data(){
      return {
        account_id: '',
        route_ids: [],
        deviation: 50,
        timer: null,
        center: {lat: -6.21462, lng: 106.84513},
        data_routes: [],
        selected_vehicle: null,
        stops: [],
        data: [],
        temp_data: [],
        show_stop: true,
        show_traffic: false,
        trafficLayer: null,
        deviations:[
          {value: 5, label:'5 m'},
          {value: 10, label:'10 m'},
          {value: 15, label:'15 m'},
          {value: 20, label:'20 m'},
          {value: 25, label:'25 m'},
          {value: 30, label:'30 m'},
          {value: 50, label:'50 m'},
          {value: 75, label:'75 m'},
          {value: 100, label:'100 m'},
        ],
        accountOptions: [],
        routeOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.data_routes = []
        this.selected_vehicle = null
        this.stops = []
        this.data = []
        if(this.route_ids.length > 0){
          for(let i = 0;i<this.route_ids.length;i++){
            this.routes.map(item => {
              if(item.id == this.route_ids[i]){
                this.data_routes.push(item)
              }
            })
            this.data_routes[i].route_points.sort(function(a, b) {
              if (a.id < b.id) return -1;
              if (a.id > b.id) return 1;
              return 0;
            });
            this.stops = this.stops.concat(this.data_routes[i].route_stops)
          }
          if(this.stops.length > 0){
            this.$refs.gmap.$mapPromise.then(() => {
              let bounds = new window.google.maps.LatLngBounds()
              this.stops.forEach(loc => {
                bounds.extend(loc.stop)
              })
              this.$nextTick().then(() => {
                this.$refs.gmap.fitBounds(bounds)
              })
              this.$refs.gmap.panToBounds(bounds, 100)
            })
          }
        }
        this.reloadData()
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('monitor_route_vehicles', ['get_data', 'clear_data']),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
      ...mapActions('alert', ['error', 'clear']),
      onChange(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.route_ids = []
      },
      onChangeRoute(event){        
        this.data_routes = []
        this.stops = []
        this.data = []
        if(event.length > 0){
          for(let i = 0;i<event.length;i++){
            this.routes.map(item => {
              if(item.id == event[i]){
                this.data_routes.push(item)
              }
            })
            this.data_routes[i].route_points.sort(function(a, b) {
              if (a.id < b.id) return -1;
              if (a.id > b.id) return 1;
              return 0;
            });
            this.stops = this.stops.concat(this.data_routes[i].route_stops)
          }
          if(this.stops.length > 0){
            this.$refs.gmap.$mapPromise.then(() => {
              let bounds = new window.google.maps.LatLngBounds()
              this.stops.forEach(loc => {
                bounds.extend(loc.stop)
              })
              this.$nextTick().then(() => {
                this.$refs.gmap.fitBounds(bounds)
              })
              this.$refs.gmap.panToBounds(bounds, 100)
            })
          }
        }
        this.temp_data.map(item => {
          for(let i = 0;i<event.length;i++){
            if(event[i] == item.route_id){
              this.data.push(item)
            }
          }
        })
      },
      selectVehicle(item){
        this.selected_vehicle = item
      },
      reloadData(){
        this.clear()
        clearInterval(this.timer)
        if(this.account_id !== ''){
          this.get_data({account_id: this.account_id})
          const self = this;
          this.timer = setInterval(function(){
            self.reloadData()
          }, process.env.VUE_APP_RELOAD_TIME)
        }
      },
      onRowClicked(item){
        this.selected_vehicle = item
        this.$nextTick().then(() => {
          let bounds = new window.google.maps.LatLngBounds()
          bounds.extend(this.selected_vehicle.gps_position)
          this.$refs.gmap.panToBounds(bounds, 100)
        })
      },
    },
    created() {
      this.$emit('onChildInit', 'Monitoring Route Vehicles')
      this.clear_data()
      this.get_all()
    },
    components:{
      MapStopComponent,
      MapVehicleComponent,
      Datatable,
      Select2,
      Multiselect,
    },
    unmounted(){
      if(this.timer){
        clearInterval(this.timer)
      }
    },
    watch:{
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({valueProp: item.id, label: item.route_name + ' - ' + item.route_code})
              this.route_ids.push(item.id)
            })
          }
          return
        }
      },
      show_traffic(nextState, prevState){
        if(nextState !== prevState){
          if(nextState == true){
            this.$refs.gmap.$mapPromise.then(map => {
              if(this.trafficLayer == null){
                this.trafficLayer = new window.google.maps.TrafficLayer()
              }
              this.trafficLayer.setMap(map)
            })
          } else {            
            this.$refs.gmap.$mapPromise.then(() => {
              if(this.trafficLayer != null)
                this.trafficLayer.setMap(null)
            })
          }
        }
      },
      monitor_route_vehicle(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.length > 0){
            const self = this
            this.temp_data = nextState.map(item => {
              let drv = item.gps_position.sensors.filter(item2 => item2.name === 'driver')
              let driver = ''
              if(drv.length > 0){
                driver = drv[0].value
              }
              let sel_route = self.data_routes.filter(item2 => item2.id == item.route_id)
              let status = calculateDeviation(item.gps_position, sel_route.length > 0 ? sel_route[0].route_points : [], self.deviation)
              return {
                route_id: item.route_id,
                id: item.id,
                vehicle_no: item.vehicle_no,
                vehicle_code: item.vehicle_code,
                gps_position: item.gps_position,
                driver: driver,
                status: status === true ? 'In Route' : 'Off Route'
              }
            })          
            if(this.data.length < 1)  {
              this.temp_data.map(item => {
                  for(let i = 0;i<this.route_ids.length;i++){
                    if(this.route_ids[i] == item.route_id){
                      this.data.push(item)
                    }
                  }
              })
            } else {
              this.temp_data.map(item => {
                for(let i = 0;i<this.data.length;i++){
                  if(this.data[i].id == item.id){
                    this.data[i].gps_position = item.gps_position
                    break
                  }
                }
              })
            }
            
            if(this.selected_vehicle !== null){
              nextState.forEach(loc => {
                if(loc.id === this.selected_vehicle.id){
                  this.selected_vehicle = loc
                }
              })
              this.$nextTick().then(() => {
                let bounds = new window.google.maps.LatLngBounds()
                bounds.extend(this.selected_vehicle.gps_position)
                this.$refs.gmap.panToBounds(bounds, 100)
              })
            }
          }
          return
        }
      }
    }
  }
</script>
<style>
.item{
  width: 16vw;
  padding: 10px;
  border: 1px solid black;
  margin: 1px 1px;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>